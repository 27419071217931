import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Theme from '@input-output-hk/front-end-core-components/components/Theme'
import Container from '@material-ui/core/Container'
import Header from '../components/Header'
const BGImage = 'https://ucarecdn.com/77efb491-8dd0-44a2-a991-75a03b9294d9/'

const StyledMain = styled.main`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
`

const FooterBG = styled.div`
  background: url(${BGImage}) no-repeat 23% center, ${({ theme }) => theme.colors.background.primaryGrad};
  background-size: contain;
  footer {
    margin-top:0;
    margin-bottom:0;
    * {
      color:${({ theme }) => theme.palette.primary.contrastText};
      font-size:97%;
    }
    hr {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`

const Main = ({ children }) => (
  <Fragment>
    <Header />
    <StyledMain>
      {children}
    </StyledMain>
    <FooterBG>
      <Container maxWidth='md'>
        <Theme.Consumer>
          {({ theme }) => (
            <Footer theme={theme.palette.type} />
          )}
        </Theme.Consumer>
      </Container>
    </FooterBG>
  </Fragment>
)

Main.propTypes = {
  children: PropTypes.node.isRequired
}

export default Main
