import { extendDefaultTheme } from '@input-output-hk/front-end-themes/themes/defaultTheme'

const primaryGrad = `linear-gradient(145deg, rgba(0,51,173,1) 0%, rgba(66,108,206,1) 100%)`

const theme = extendDefaultTheme({
  type: 'light',
  name: 'Cardano Light',
  overrides: {
    MuiButton: {
      root: {
        padding: '1.4rem 4rem'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiIconButton: {
      root: {
        color: 'black'
      }
    }
  },
  colors: {
    primary: {
      main: '#0a38a6',
      light: '#3b5fb7',
      dark: '#0a38a6',
      contrastText: '#fff',
      rgb: '0, 51, 173'
    },
    secondary: {
      main: '#FF5653',
      light: '#ff7775',
      dark: '#b23c3a',
      contrastText: '#fff'
    },
    accentColour: {
      main: '#ff7676'
    },
    text: {
      primary: '#1d1e21',
      secondary: '#fff',
      disabled: '#bbb',
      hint: '#eee'
    },
    background: {
      default: '#fdfdfb',
      primary: '#000',
      primaryGrad: primaryGrad,
      hero: '#f8f8f5',
      alternateBackground: 'rgba(204, 214, 238, 0.15)'
    }
  },
  shape: {
    borderRadius: 24
  },
  shadows: ['none'],
  typography: {
    baseFontSize: 20,
    lineHeight: 1.6,
    fontWeight: 400,
    fontFamily: 'Chivo, sans-serif',
    googleFontsURL: 'https://fonts.googleapis.com/css?family=Chivo:300,400,700',
    h1: {
      fontSize: 3.4,
      fontWeight: 700,
      lineHeight: 1.1,
      letterSpacing: 0,
      textTransform: 'capitalize'
    },
    h2: {
      fontSize: 1.728,
      fontWeight: 700,
      letterSpacing: 0.05,
      lineHeight: 1.15,
      textTransform: 'capitalize'
    },
    h3: {
      fontSize: 1.44,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0.05,
      textTransform: 'capitalize'
    },
    h4: {
      fontSize: 1.1,
      fontWeight: 700,
      letterSpacing: 0.05,
      lineHeight: 1.2,
      textTransform: 'capitalize'
    },
    h5: {
      fontSize: 1,
      fontWeight: 700,
      letterSpacing: 0.15,
      lineHeight: 1.4,
      textTransform: 'capitalize'
    },
    h6: {
      fontSize: 0.6,
      fontWeight: 700,
      lineHeight: 1.4,
      textTransform: 'uppercase'
    },
    body: {
      fontSize: 1,
      fontWeight: 300,
      letterSpacing: 0.05,
      lineHeight: 1.5
    },
    small: {
      fontSize: 0.75,
      fontWeight: 400,
      letterSpacing: 0.05,
      lineHeight: 1.5,
      '@media (min-width:2049px)': {
        fontSize: 0.8,
        lineHeight: 1.8
      }
    },
    button: {
      fontSize: 0.75,
      fontWeight: 400,
      letterSpacing: 0.1,
      lineHeight: 1.7,
      textTransform: 'none',
      borderRadius: 'none'
    },
    body2: {
      fontSize: 1.6
    }
  }
})

export const getThemes = () => [{ key: 'cardano', config: theme }]
