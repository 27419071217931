import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import IndexPageQuery from '../queries/IndexPageQuery'
import ShelleySection from '../components/ShelleySection'
import SpeakerSection from '../components/Speakers/SpeakerSection'
import CtaSection from '../components/CtaSection'
import SponsorSection from '../components/SponsorSection'

export default () => (
  <IndexPageQuery
    render={(content) => (
      <Layout>
        <Hero />
        <ShelleySection />
        <SpeakerSection />
        <CtaSection />
        <SponsorSection />
      </Layout>
    )}
  />
)
