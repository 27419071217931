import React from 'react'
import Container from '@material-ui/core/Container'
import { Grid, Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

const GContainer = props => <Grid container {...props} />
const GItem = props => <Grid item {...props} />

const StyledSection = styled.section`
  padding-top:6rem;
  padding-bottom:12rem;
  h3 {
    margin:0;
    padding:0;
  }
  .headingDot {
    position:relative;
    &:before {
      content: '';
      position:absolute;
      top:-1.5rem;
      left:0;
      width:1rem;
      height:1rem;
      border-radius:100%;
      background: ${({ theme }) => theme.palette.accentColour.main}
    }
  }
  .yt-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    box-shadow: 0 4px 12px 0 ${({ theme }) => theme.palette.primary.light};
    iframe {
      position:absolute;
      top:0;
      left: 0;
      width:100%;
      height:100%;
    }
  }
`

const ShelleySection = () => (
  <StyledSection>
    <Container maxWidth='md'>
      <GContainer>
        <GItem md={4}>
          <Typography variant='h3' component='h3' className='headingDot' color='primary'>A new era for Cardano</Typography>
        </GItem>
        <GItem md={8}>
          <Box mt={8} mb={4}>
            <Typography variant='body1'><strong>On July 2nd & 3rd we held a 2-day virtual summit to mark the beginning of this new era for Cardano.</strong></Typography>
          </Box>
          <Box mt={8} mb={4}>
            <div className='yt-container'>
              <iframe src='https://www.youtube.com/embed/BBX1LC5Quxs?rel=0' frameBorder='0' allowFullscreen />
            </div>
          </Box>
        </GItem>
      </GContainer>
      <GContainer>
        <GItem md={4}>
          <Typography variant='h3' component='h3' className='headingDot' color='primary'>Watch the talks on YouTube</Typography>
        </GItem>
        <GItem md={8}>
          <Box mt={8} mb={4}>
            <Typography variant='body1'><strong>Rewatch the conversation, with speakers from IOHK, EMURGO & the Cardano Foundation, along with our commercial partners, community leaders & some very special guests.</strong></Typography>
          </Box>
          <Box mt={8} mb={4}>
            <div className='yt-container'>
              <iframe src='https://www.youtube.com/embed/videoseries?list=PLnPTB0CuBOBwnSOV7d25N_opV_Ii3PiGf' frameBorder='0' allowFullScreen />
            </div>
          </Box>
        </GItem>
      </GContainer>
    </Container>
  </StyledSection>
)

export default ShelleySection
