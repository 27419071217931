const Vince = 'https://ucarecdn.com/60957b69-1b55-45f4-ab9b-0539840ffa92/'
const Caitlin = 'https://ucarecdn.com/c820297a-1783-4ec1-bf3e-952e1bf9390a/'
const Stephen = 'https://ucarecdn.com/c355e58b-dc58-4a7d-bc76-ba0888782fef/'
const Charles = 'https://ucarecdn.com/bc81945a-6044-4417-a66a-4a2571725c4e/'
const John = 'https://ucarecdn.com/92340593-5a41-4ef1-9e3d-328727ba63bb/'
const Aggelos = 'https://ucarecdn.com/5375a52b-bcca-403e-be6e-62dd996c655d/'

export default [
  {
    name: 'Vinton G. Cerf',
    desc: 'VP & Chief Internet Evangelist, Google',
    img: Vince,
    social: {
      tw: 'https://twitter.com/vgcerf',
      web: 'https://research.google/people/author32412/'
    }
  },
  {
    name: 'Caitlin Long',
    desc: 'Co-founder, Wyoming Blockchain Coalition',
    img: Caitlin,
    social: {
      tw: 'https://twitter.com/CaitlinLong_',
      web: 'https://www.linkedin.com/company/wyoming-blockchain-coalition/',
      in: 'https://www.linkedin.com/in/caitlin-long-/'
    }
  },
  {
    name: 'Stephen Wolfram',
    desc: 'Founder & CEO, Wolfram Research',
    img: Stephen,
    social: {
      tw: 'https://twitter.com/stephen_wolfram',
      web: 'https://www.stephenwolfram.com/',
      in: 'https://www.linkedin.com/in/stephenwolfram/'
    }
  },
  {
    name: 'Charles Hoskinson',
    desc: 'CEO & co-founder, IOHK',
    img: Charles,
    social: {
      tw: 'https://twitter.com/IOHK_Charles',
      web: 'https://iohk.io/',
      yt: 'https://www.youtube.com/watch?v=Ja9D0kpksxw'
    }
  },
  {
    name: 'Aggelos Kiayias',
    desc: 'Chief Scientist, IOHK. Professor at University of Edinburgh',
    img: Aggelos,
    social: {
      tw: 'https://twitter.com/sol3gga',
      web: 'https://iohk.io/en/research/library/authors/aggelos-kiayias/',
      yt: 'https://youtu.be/LCeK_4o-NCc'
    }
  },
  {
    name: 'John O’Connor',
    desc: 'Director of African Operations, IOHK',
    img: John,
    social: {
      tw: 'https://twitter.com/jjtoconnor',
      web: 'https://iohk.io/en/team/john-oconnor',
      in: 'https://www.linkedin.com/in/jjtoconnor/'
    }
  }
]
