import React from 'react'
import Container from '@material-ui/core/Container'
import { Link } from '@input-output-hk/front-end-core-components'
import { Box, Button, Typography } from '@material-ui/core'
import styled from 'styled-components'
const BGImage = 'https://ucarecdn.com/77efb491-8dd0-44a2-a991-75a03b9294d9/'

const StyledSection = styled.section`
  background: url(${BGImage}) no-repeat center center, ${({ theme }) => theme.palette.secondary.main};
  background-size: contain;
  padding-top:8rem;
  padding-bottom:8rem;
  h3 {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    margin:0;
    padding:0;
  }
  button {
    box-shadow: 0 4px 12px 0 ${({ theme }) => theme.palette.secondary.dark};
    text-transform: uppercase;
  }
  .headingDot {
    position:relative;
    &:before {
      content: '';
      position:absolute;
      top:-1.5rem;
      left:0;
      width:1rem;
      height:1rem;
      border-radius:100%;
      background: ${({ theme }) => theme.palette.accentColour.main}
    }
  }
`

const CtaSection = () => (
  <StyledSection>
    <Container maxWidth='md'>
      <Box textAlign='center'>
        <Typography variant='h3'>2 - 3 July 2020</Typography>
        <Box mt={4} mb={4}>
          <Link href='https://www.youtube.com/playlist?list=PLnPTB0CuBOBwnSOV7d25N_opV_Ii3PiGf'>
            <Button variant='contained' color='default'>Watch on YouTube</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  </StyledSection>
)

export default CtaSection
