import React from 'react'
import styled from 'styled-components'
import { Link } from '@input-output-hk/front-end-core-components'
import Container from '@material-ui/core/Container'
import { Box, Typography } from '@material-ui/core'
import GlobalContentQuery from '../queries/GlobalContentQuery'

const LogoURL = 'https://ucarecdn.com/0fb56a06-c56b-4feb-b557-72922aa8ddc0/'

const PageHead = styled.header`
  position:relative;
  z-index: 2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  * {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  h1, a {
    margin: 0;
    padding: 0;
  }
  @media(max-width:959px) {
    .hashtag {
      font-size: 1.6rem;
    }
  }
`

const Title = styled(Typography)`
  font-size:2.4rem;
  text-transform:uppercase;
  font-weight:500;
  letter-spacing:1px;
  margin-left: 1rem !important;
`

const Logo = styled.img`
  display: block;
  max-width:4rem;
  min-height:4rem;
`

export default () => (
  <PageHead>
    <Container maxWidth='md'>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <a href='/'>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Logo src={LogoURL} />
            <GlobalContentQuery
              render={(content) => (
                <Title variant='h1' style={{ fontSize: '2.4rem', fontWeight: '500' }}>{content.short_name}</Title>
              )}
            />
          </Box>
        </a>
        <Link className='hashtag' href='https://twitter.com/intent/tweet?text=I%27m%20attending%20the%20%23cardano2020%20summit%20-%20join%20me%20at%20https%3A%2F%2Fcardanosummit.iohk.io%2F%20%23ShelleyEdition'>
          #cardano2020
        </Link>
      </Box>
    </Container>
  </PageHead>
)
