import React from 'react'
import { Link, Image } from '@input-output-hk/front-end-core-components'
import Container from '@material-ui/core/Container'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import SpeakersData from './SpeakersData'
import styled from 'styled-components'
import { FaTwitter, FaYoutube, FaGlobe, FaLinkedinIn, FaGithub, FaFacebookSquare } from 'react-icons/fa'
const BGoval = 'https://ucarecdn.com/f53befa8-f7e4-4b82-b147-8c6d3c33433e/'

const GContainer = props => <Grid container {...props} />
const GItem = props => <Grid item {...props} />

const StyledSection = styled.section`
  background: url(${BGoval}) no-repeat right bottom, ${({ theme }) => theme.colors.background.primaryGrad};
  background-size: contain;
  padding-top:12rem;
  padding-bottom: 12rem;
  ul {
    list-style-type: none;
    margin:0;
    padding:0;
    li {
      display:inline-block;
    }
  }
  h3, p {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  h3 {
    margin:0;
    padding:0;
  }
  .headingDot {
    position:relative;
    &:before {
      content: '';
      position:absolute;
      top:-1.5rem;
      left:0;
      width:1rem;
      height:1rem;
      border-radius:100%;
      background: ${({ theme }) => theme.palette.accentColour.main};
    }
  }
  .speaker-card {
    display: flex;
  }
  .speaker {
    border-radius: 0.4rem;
    display:flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.background.primaryGrad};
    box-shadow: 0 4px 12px 0 ${({ theme }) => theme.palette.primary.dark};
    h4 {
      min-height: 7.2rem;
      margin:0;
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
    h4, p, ul {
      padding: 1rem 2rem;
    }
    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .social-icon {
      a {
        color: ${({ theme }) => theme.palette.secondary.main};
      }
      & + .social-icon {
        margin-left: 2rem;
      }
    }
  }
  .speaker-card-bottom {
    margin-top: auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        max-width:223px;
      }
    }
  }
  .text-flow {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width:220px;
    }
  }
`

const SpeakerSection = () => (
  <StyledSection>
    <Container maxWidth='md'>
      <GContainer>
        <GItem md={4}>
          <Typography variant='h3' component='h3' className='headingDot'>Speakers</Typography>
        </GItem>
        <GItem md={8}>
          <Box mb={4}>
            <Typography variant='body1'><strong>Creators, visionaries, innovators, change makers; the Cardano Virtual Summit 2020 featured the brightest minds at the heart of blockchain… and beyond.</strong></Typography>
          </Box>
        </GItem>
      </GContainer>
      <GContainer>
        <GItem md={2}>&nbsp;</GItem>
        <GItem md={10}>
          <GContainer spacing={4}>
            {SpeakersData &&
              SpeakersData.map((speaker, i) => (
                <GItem className='speaker-card' xs={12} sm={6} md={4} key={`${speaker.name.replace(' ', '-').toLowerCase()}-${i}`}>
                  <Box className='speaker' textAlign='center'>
                    <h4 className='text-flow'>{speaker.name}</h4>
                    <Typography variant='body2' className='text-flow'>{speaker.desc}</Typography>
                    <Box className='speaker-card-bottom'>
                      <Image src={speaker.img} alt={speaker.name} maintainTransparency />
                      <ul>
                        {speaker.social.tw &&
                          <li className='social-icon'>
                            <Link href={speaker.social.tw}>
                              <FaTwitter />
                            </Link>
                          </li>}
                        {speaker.social.yt &&
                          <li className='social-icon'>
                            <Link href={speaker.social.yt}>
                              <FaYoutube />
                            </Link>
                          </li>}
                        {speaker.social.web &&
                          <li className='social-icon'>
                            <Link href={speaker.social.web}>
                              <FaGlobe />
                            </Link>
                          </li>}
                        {speaker.social.in &&
                          <li className='social-icon'>
                            <Link href={speaker.social.in}>
                              <FaLinkedinIn />
                            </Link>
                          </li>}
                        {speaker.social.gh &&
                          <li className='social-icon'>
                            <Link href={speaker.social.gh}>
                              <FaGithub />
                            </Link>
                          </li>}
                        {speaker.social.fb &&
                          <li className='social-icon'>
                            <Link href={speaker.social.fb}>
                              <FaFacebookSquare />
                            </Link>
                          </li>}
                      </ul>
                    </Box>
                  </Box>
                </GItem>
              ))
            }
          </GContainer>
        </GItem>
      </GContainer>
      <GContainer>
        <GItem md={2}>
          &nbsp;
        </GItem>
        <GItem md={10}>
          <Box mt={4} display='flex' flex-direction='column' justifyContent='center' alignItems='center'>
            <Button variant='contained' href='/images/virtual-summit-agenda.pdf' target='_blank' rel='noopener noreferrer'><strong>Full list of speakers &amp; agenda</strong></Button>
          </Box>
        </GItem>
      </GContainer>
    </Container>
  </StyledSection>
)

export default SpeakerSection
