import React from 'react'
import { Link } from '@input-output-hk/front-end-core-components'
import Container from '@material-ui/core/Container'
import { Grid, Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

const IOHK = 'https://ucarecdn.com/1de06714-080c-4e1a-8aee-88aa692fae92/'
const CF = 'https://ucarecdn.com/1d80b89f-9ed6-4165-be6a-d3dbdd1d1041/'
const Emurgo = 'https://ucarecdn.com/7d45e421-b48e-48ba-ac5d-81179ffc727f/'

const GContainer = props => <Grid container {...props} />
const GItem = props => <Grid item {...props} />

const StyledSection = styled.section`
  margin: 0;
  padding-top:12rem;
  padding-bottom:12rem;
  h3 {
    margin:0;
    padding:0;
  }
  img {
    max-width: 18rem;
  }
  .headingDot {
    position:relative;
    &:before {
      content: '';
      position:absolute;
      top:-1.5rem;
      left:0;
      width:1rem;
      height:1rem;
      border-radius:100%;
      background: ${({ theme }) => theme.palette.accentColour.main}
    }
  }
  .sponsor {
    text-align:center;
  }
  @media (max-width:959px) {
    img {
      max-width: 30rem;
    }
    .sponsor {
      & + .sponsor {
        margin-top: 4rem;
      }
    }
  }
`

const SponsorSection = () => (
  <StyledSection>
    <Container maxWidth='md'>
      <GContainer>
        <GItem xs={12} md={4}>
          <Typography variant='h3' component='h3' className='headingDot' color='primary'>Brought to you by</Typography>
        </GItem>
        <GItem xs={12} md={8}>
          <Box mb={4}>
            <Typography variant='body1'>&nbsp;</Typography>
          </Box>
        </GItem>
      </GContainer>
      <Box mt={4} mb={4} pb={4}>
        <GContainer>
          <GItem xs={12} md={4} className='sponsor'>
            <Link href='https://iohk.io/'>
              <img src={IOHK} alt='IOHK' />
            </Link>
          </GItem>
          <GItem xs={12} md={4} className='sponsor'>
            <Link href='https://cardanofoundation.org/'>
              <img src={CF} alt='Cardano Foundation' />
            </Link>
          </GItem>
          <GItem xs={12} md={4} className='sponsor'>
            <Link href='https://emurgo.io/en'>
              <img src={Emurgo} alt='Emurgo' />
            </Link>
          </GItem>
        </GContainer>
      </Box>
      <GContainer>
        <GItem xs={12} md={6}>
          <Typography variant='h3' component='h3' className='headingDot' color='primary'>Livestream Partner</Typography>
        </GItem>
      </GContainer>
      <Box mt={4} mb={4} pb={4}>
        <GContainer spacing={7}>
          <GItem xs={12} md={12} className='sponsor'>
            <Link href='https://coinmarketcap.com/'>
              <img src='/images/coinmarketcap.png' alt='AMB Crypto' />
            </Link>
          </GItem>
        </GContainer>
      </Box>
      <GContainer>
        <GItem xs={12} md={6}>
          <Typography variant='h3' component='h3' className='headingDot' color='primary'>Media Sponsors</Typography>
        </GItem>
      </GContainer>
      <Box mt={4} mb={4} pb={4}>
        <GContainer spacing={7}>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://eng.ambcrypto.com/'>
              <img src='/images/AMBCrypto.png' alt='AMB Crypto' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://coincodex.com/'>
              <img src='/images/CoinCodex.png' alt='Coin Codex' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.bitcoininsider.org/'>
              <img src='/images/BitcoinInsider.png' alt='Bitcoin Insider' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.coinkolik.com/'>
              <img src='/images/CoinKolik.png' alt='CoinKolik' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://crypto-economy.com/'>
              <img src='/images/CryptoEconomy.png' alt='Crypto Economy' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.cryptopolitan.com/'>
              <img src='/images/Cryptopolitan.png' alt='Cryptopolitan' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://en.cryptonomist.ch/'>
              <img src='/images/Cryptonomist.png' alt='Cryptonomist' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://cryptocoindaddy.com/'>
              <img src='/images/CryptocoinDaddy.png' alt='Cryptocoin Daddy' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://u.today/'>
              <img src='/images/U-Today.png' alt='U.Today' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.panewslab.com/'>
              <img src='/images/PANews.png' alt='PA News' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://kryptomoney.com/'>
              <img src='/images/KryptoMoney.png' alt='Krypto Money' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://cassiopeia-ltd.com/financial-fox/'>
              <img src='/images/financialfox.png' alt='Financial Fox' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.altcoinbuzz.io/ '>
              <img src='/images/altcoinbuzz.png' alt='AltCoinBuzz' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://coinpedia.org/'>
              <img src='/images/coinpedia.png' alt='CoinPedia' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.digitalbulletin.com/'>
              <img src='/images/digitalbulletin.png' alt='Digital Bulletin' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://www.cityam.com/crypto/'>
              <img src='/images/crypto-am.jpg' alt='Crypto AM' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://cryptobriefing.com/'>
              <img src='/images/crypto-briefing.png' alt='Crypto Briefing' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://cryptoslate.com/'>
              <img src='/images/cryptoslate.png' alt='CryptoSlate' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://bit2buzz.com/'>
              <img src='/images/bit2buzz.png' alt='Bit2Buzz' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://finyear.com/'>
              <img src='/images/finyear.png' alt='Finyear' />
            </Link>
          </GItem>
          <GItem xs={12} md={3} className='sponsor'>
            <Link href='https://blockchain.news'>
              <img src='/images/blockchain-news.png' alt='Blockchain News' />
            </Link>
          </GItem>
        </GContainer>
      </Box>
    </Container>
  </StyledSection>
)

export default SponsorSection
