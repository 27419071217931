import React from 'react'
import Container from '@material-ui/core/Container'
import { Link, Image } from '@input-output-hk/front-end-core-components'
import { Box, Grid, Button } from '@material-ui/core'
import IndexPageQuery from '../queries/IndexPageQuery'
import styled from 'styled-components'

const BGImage = 'https://ucarecdn.com/77efb491-8dd0-44a2-a991-75a03b9294d9/'
const IOHKlogo = 'https://ucarecdn.com/bc826210-823f-4a08-adb4-111a8dd34495/'
const CardanoFlogo = 'https://ucarecdn.com/72c68e7c-5097-4de7-879e-2ef1e6fb5e84/'
const Emurgologo = 'https://ucarecdn.com/cf1f01a2-4047-49d5-8149-9b3d1c43bded/'

const GContainer = props => <Grid container {...props} />
const GItem = props => <Grid item {...props} />

const StyledHero = styled.section`
  position:relative;
  margin-top: -6rem;
  padding:18rem 0 18rem 0;
  img {
    display:block;
  }
  h1, h2, h3, p, a {
    line-height:1;
    margin:0;
    padding: 0;
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  background: url(${BGImage}) no-repeat 30% center, ${({ theme }) => theme.colors.background.primaryGrad};
  background-size: contain;
  .splat-wrap {
    picture, img {
      max-width:20rem;
    }
  }
  h1 {
    text-shadow: 0 0 2.4rem ${({ theme }) => theme.palette.primary.dark};
    font-size:12rem;
    margin-bottom: 4rem;
  }
  h1, h2 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 8px;
  }
  h2 {
    text-shadow: 0 0 2.4rem ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.secondary.main};
    letter-spacing: 12px;
    margin-bottom: 4rem;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 18px;
    margin-bottom: 4rem;
  }
  button {
    box-shadow: 0 4px 1.2rem 0 ${({ theme }) => theme.palette.primary.dark};
    text-transform:uppercase;
  }
  .date-cta {
    margin-bottom: 4rem;
    h3 {
      letter-spacing:6px;
      font-weight:bold;
    }
  }
  .hero-text {
    text-align:right;
  }
  .logo-row {
    margin-bottom: 8rem;
  }
  @media (min-width: 960px) {
    .logo-row {
      margin-left:10rem;
    }
  }
  @media (max-width: 959px) {
    background-size: cover;
    padding:5rem 0 0 0;
    .MuiGrid-container > div {
      width:100%;
      padding-top:0;
      padding-bottom:0;
      img {
        margin: 0 auto;
        max-width:18rem;
        & + img {
          margin-top: 4rem;
        }
      }
    }
    .splat-wrap {
      picture, img {
        margin: 0 auto;
        max-width:15rem;
        transform: rotate(90deg);
      }
    }
    .hero-text {
      text-align:center;
    }
    h1 {
      font-size: 6rem;
    }
    h2, h3 {
      font-size: 2.5rem;
    }
    .logo-row {
      flex-direction: column;
    }
    .splat-wrap {
    }
    .date-cta {
      text-align: center;
    }
  }
`

const Hero = () => (
  <IndexPageQuery
    render={(content) => (
      <StyledHero>
        <Container maxWidth='md'>
          <GContainer>
            <GItem md={3}>
              <Box className='splat-wrap'>
                <Image className='cardano-splat' src='https://ucarecdn.com/f17c6182-2cf2-4645-af0f-7b76e24e752e/' alt={`${content.hero_title}-graphic`} maintainTransparency />
              </Box>
            </GItem>
            <GItem md={9}>
              <Box className='hero-text'>
                <h1>{content.hero_title}</h1>
                <h2>{content.hero_subtitle}</h2>
                <h3>{content.hero_lead}</h3>
                <Box className='logo-row' display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                  <img src={IOHKlogo} alt='iohk logo' />
                  <img src={CardanoFlogo} alt='cardano foundation logo' />
                  <img src={Emurgologo} alt='emurgo logo' />
                </Box>
              </Box>
            </GItem>
            <GItem xs={12} md={12}>
              <Box className='date-cta'>
                <h3>2 - 3 July 2020</h3>
                <Link href='https://www.youtube.com/playlist?list=PLnPTB0CuBOBwnSOV7d25N_opV_Ii3PiGf'>
                  <Button variant='contained' color='secondary'>
                    Watch on YouTube
                  </Button>
                </Link>
              </Box>
            </GItem>
          </GContainer>
        </Container>
      </StyledHero>
    )}
  />
)

export default Hero
