export default {
  body: `Data Protection Policy`,
  dataProtectLink: `https://ucarecdn.com/6b3cfda0-9864-4319-8e97-2ffd73ca84a8/-/inline/yes/`,
  cardanoLinks: {
    title: 'More about Cardano',
    links: [
      { label: 'Cardano Explorer', href: 'https://cardanoexplorer.com/' },
      { label: 'Cardano Foundation', href: 'https://cardanofoundation.org/' },
      { label: 'Documentation', href: 'https://cardanodocs.com/introduction/' },
      { label: 'Cardano.org', href: 'https://www.cardano.org/' },
      { label: 'Why Cardano', href: 'https://whycardano.com/' },
      { label: 'Daedalus', href: 'https://daedaluswallet.io/' },
      { label: 'Cardano Testnets', href: 'https://testnet.iohkdev.io/' },
      { label: 'Cardano Rust', href: 'https://cardanorust.iohkdev.io/' }
    ]
  },
  communityLinks: {
    title: 'Join the community',
    links: [
      { label: 'Cardano Community', href: 'https://cardano.org/' },
      { label: 'Cardano Telegram', href: 'https://t.me/CardanoAnnouncements/' },
      { label: 'Cardano Forum', href: 'https://forum.cardano.org/' },
      { label: 'Cardano Reddit', href: 'https://www.reddit.com/r/cardano/' },
      { label: 'IOHK', href: 'https://www.iohk.io/' },
      { label: 'IOHK YouTube', href: 'https://www.youtube.com/channel/UCBJ0p9aCW-W82TwNM-z3V2w' },
      { label: 'CF YouTube', href: 'https://www.youtube.com/channel/UCbQ9vGfezru1YRI1zDCtTGg' },
      { label: 'CF Twitter', href: 'https://twitter.com/cardanostiftung' }
    ]
  }
}
